@import "src/styles/variables";

.home {
  // background-color: $color-white;
  position: relative;
  color: $black;
  overflow: hidden;
  &-noscroll {
    overflow-y: hidden;
  }

  .background-lines-container {
    width: 100%;
    height: calc(100% - 710px);
    max-width: 1128px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    position: absolute;
    top: 710px;
    left: 0;
    right: 0;
    z-index: 0;

    .backline {
      z-index: 0;
      width: 1px;
      height: 100%;
      background-color: #f0f3f9;
      // background-color: red;
      position: relative;
    }
  }
}

.button-outlined,
.button-filled {
  padding: 8px 16px;
  @include flex-center;
  backdrop-filter: blur(1px);
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.button-outlined {
  border: 1px solid #e4e8f2;
  background-color: #fff;

  &:hover {
    background-color: #efecfe;
    border: 1px solid #fff;
    a {
      color: #4900b3;
    }
  }
  a {
    color: $black;
  }
}
.button-filled {
  background-color: #6800ff;
  color: #fff;
  border: none;
  box-shadow: 0 2px 0 rgba(104, 0, 255, 0.08);

  a {
    color: $white;
  }

  &:hover {
    background-color: #5e00e6;
  }
}

.section-title {
  // font-family: $font-family-primary;
  font-size: 3.6rem;
  // font-weight: $font-weight-primary;
  margin-bottom: 8rem;
  text-align: center;
}

@media (max-width: 500px) {
  .background-lines-container {
    opacity: 0;
  }
}
