@import "src/styles/variables.scss";

.navbar {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: background-color 0.3s ease-in-out;
  // background-color: red;

  .expanded {
    height: auto;
  }
  &-mobile {
    // background-color: #a466ff;
    background-color: $white;
    height: 92px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);

    &-header {
      @include flex-between;
      margin-bottom: 20px;
      .navbar-logo {
        img {
          height: 40px;
        }
        a {
          font-size: 18px;
        }
      }
    }
    &-body {
      text-align: center;
      padding-bottom: 20px;

      p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 30px;
      }
      button {
        margin: 0 auto;
      }
    }
    &-menu {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
      margin-bottom: 40px;

      a {
        font-size: 15px;
        color: $black;
      }
    }

    .navbar-action-language {
      margin-top: 20px;
      justify-content: center;
      padding-bottom: 20px;
    }

    .menu-button {
      border: none;
      background-color: transparent;
    }

    .logo-svg {
      width: 160px;
      height: 52px;
    }
    .menu-svg,
    .close-svg {
      width: 40px;
      height: 40px;
    }
  }

  &.scrolled {
    // background-color: rgba(210, 179, 255, 0.8);
    background-color: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  }

  &-content {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &-logo {
    img {
      height: 60px;
      object-fit: contain;
    }
    a {
      display: flex;
      align-items: center;
      column-gap: 12px;
      color: $black;
      font-size: 24px;
      font-weight: 600;
      @include gradient;
    }
  }

  &-menu {
    .ant-anchor-wrapper-horizontal::before {
      border-bottom: 0;
    }

    .ant-anchor-link {
      padding-inline: 30px 0 !important;

      &:nth-child(2) {
        padding-inline: 0 !important;
      }
    }
  }

  &-action {
    display: flex;
    align-items: center;
    column-gap: 30px;

    p {
      font-size: 15px;
      font-weight: 300;
    }

    &-language {
      display: flex;
      column-gap: 20px;

      button {
        border: none;
        font-size: 15px;
        background-color: transparent;
        cursor: pointer;
      }

      a {
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    &-mobile {
      height: 60px;
      padding-top: 0;
      padding-bottom: 0;

      &-header {
        margin-bottom: 0;
        height: 100%;
        padding: 20px 0;
      }
      .logo-svg {
        width: 100px;
        height: 32px;
      }
      .menu-svg,
      .close-svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
