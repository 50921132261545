@import "src/styles/variables";

.section-products {
  padding: 100px 0;
  background-color: #f4f8fc;

  .products {
    &-wrap {
      position: relative;
      z-index: 3;
      max-width: 1430px;

      h3 {
        letter-spacing: -0.02em;
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
      }
      h4 {
        letter-spacing: -0.02em;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
      }
      p {
        letter-spacing: -0.02em;
        font-size: 18px;
        line-height: 32px;
      }
    }
    &-title {
      h2 {
        letter-spacing: -0.02em;
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 20px;
        @include gradient;
      }
      p {
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 80px;
      }
    }
    &-cards {
      grid-column-gap: 64px;
      grid-row-gap: 56px;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-self: center;
      justify-items: center;
      display: grid;
      position: relative;
      z-index: 3;
      margin: 0 80px;

      .background-effect {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 700px;
        z-index: 0;
      }

      .img-card {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        z-index: 3;
        cursor: pointer;

        &-wrap {
          width: 100%;
          border-radius: 12px;
          overflow: hidden;
          margin-bottom: 8px;
          height: 290px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .ant-image-mask {
          display: none;
        }
        .ant-image {
          width: 100%;
          height: 100%;
        }
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;

          &:hover {
            transform: scale(108%);
          }
        }

        &-detail {
          padding: 16px;
        }
        h4 {
          margin-bottom: 16px;
        }
      }

      .card-2 {
        top: 30%;
      }

      .card-2,
      .card-4 {
        will-change: transform;
        transform: translate3d(0px, 50.1246px, 0px) scale3d(1, 1, 1)
          rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transition: transform 0.5s ease;
        position: relative;
      }
      .card-4 {
        top: 30%;
      }
      .text-card {
        h3 {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .section-products {
    .products-cards {
      margin: 0;
    }
  }
}
@media (max-width: 848px) {
  .section-products {
    .products {
      &-cards {
        grid-template-columns: 1fr;
        margin-bottom: 60px;
        grid-row-gap: 70px;

        .img-card,
        .text-card {
          max-width: 500px !important;
        }
        .card-2 {
          top: -22%;
        }
        .card-4 {
          top: -32%;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .section-products {
    .products {
      &-wrap {
        h2 {
          font-size: 40px;
          line-height: 48px;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .section-products {
    padding: 80px 0;
    .products {
      &-wrap {
        h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 60px;
        }
        h3 {
          font-size: 24px;
          line-height: 32px;
          max-width: 304px;
        }
        h4 {
          font-size: 24px;
          line-height: 32px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &-cards {
        .img-card {
          order: 2;
          &-wrap {
            height: 220px;
          }
        }
        .card-2 {
          order: 1;
        }
      }
    }
  }
}
