@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables.scss";

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // list-style-type: none;
}

html,
body {
  font-family: $font-primary;
  scroll-behavior: smooth;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.root {
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.form {
  width: 100%;
  padding: 0 20px 1px;
  &-form {
    margin: 0 20px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
}
.full {
  width: 100%;
}

.half {
  width: calc(50% - 1rem);
}

.input {
  outline: none !important;
}
