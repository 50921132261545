// Colors
$primary: #6800ff;
$black: #060a23;
$black-01: #495057;
$white: #fff;
$header_bg: #fafafc;
$border: rgba(0, 0, 0, 0.05);
$transition: all 0.3s ease-in-out;
$shadow_card: 0 10px 20px 0 rgba(0, 0, 0, 0.075);

// Font family
$font-primary: "Poppins", sans-serif;

@mixin gradient {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#9b41e9 0%, #5e1e91 100%);
  -webkit-background-clip: text;
}

// Shadow styles
@mixin shadow {
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: $shadow;
  }
}
// Flex styles
@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin line-clamp1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@mixin line-clamp2 {
  @include line-clamp1;
  -webkit-line-clamp: 2;
}

.line-clamp1 {
  @include line-clamp1;
}

//Scroll
@mixin scroll {
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f2f2f7;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #878a99;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #878a99;
  }
}
@mixin scroll-none {
  -webkit-scrollbar-width: none; /* Safari and Chrome */
  scrollbar-width: none; /* Firefox */
}
