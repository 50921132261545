@import "src/styles/variables";

.section-hero {
  padding: 200px 0 100px 0;
  color: #060a23;
  position: relative;
  z-index: 3;
  background-image: url("../../../../assets/img/hero.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .hero {
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    &-content {
      display: flex;
      gap: 60px;
      align-items: center;

      h1 {
        font-size: 64px;
        line-height: 72px;
        font-weight: 600;
        letter-spacing: -0.02em;
        margin-bottom: 24px;
        @include gradient;
      }
      h4 {
        letter-spacing: -0.02em;
        font-size: 20px;
        line-height: 32px;
        font-weight: 400;
      }

      &-left {
        width: 40%;
      }
      &-right {
        width: 60%;
        position: relative;
      }
    }
    &-img-1 {
      width: 100%;
      object-fit: contain;
    }
    &-img-2 {
      width: 70%;
      object-fit: contain;
      position: absolute;
      top: 50%;
      transform: translateY(-30%);
      right: 0;
    }
    &-img-3 {
      width: 15%;
      object-fit: contain;
      position: absolute;
      bottom: 8%;
      right: 4%;
      // transform: translateY(-30%);
    }
    &-buttons {
      @include flex;
      gap: 1.2rem;
      margin-bottom: 2.4rem;
      align-self: center;

      .filled,
      .outlined {
        padding: 12px 24px !important;
      }
    }
  }
}

@media (max-width: 950px) {
  .section-hero {
    .hero {
      &-content {
        flex-direction: column;
        &-left {
          width: 100%;
          text-align: center;
        }
        &-right {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .section-hero {
    padding: 160px 0 60px 0;
    .hero {
      &-wrap {
        h1 {
          font-size: 34px;
          line-height: 48px;
        }
      }
    }
  }
}
