@import "src/styles/variables";

.section-partners {
  padding: 80px 0;
  background-color: #fff;
  position: relative;
  z-index: 5;

  .partners {
    &-wrap {
      h2 {
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 80px;
        max-width: 800px;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }
    }
    &-slide-card {
      // height: 140px;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 20px;
      background-color: $white;

      h4 {
        font-size: 18px;
        font-weight: 500;
        margin: 10px 0 12px;
      }
      p {
        font-size: 15px;
        color: $black-01;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-img-wrap {
      height: 120px;
    }
  }
}

@media (max-width: 800px) {
  .section-partners {
    .partners {
      &-wrap {
        h2 {
          max-width: 500px;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 80px;
        }
      }
      &-slide-card {
        padding: 0;
      }
    }
  }
}
@media (max-width: 600px) {
  .section-partners {
    padding-bottom: 80px;
    .partners {
      &-wrap {
        h2 {
          max-width: 100%;
          margin-bottom: 60px;
          font-size: 28px;
          line-height: 32px;
          width: 100%;
        }
      }
      &-slide-card {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
