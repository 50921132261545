@import "src/styles/variables";

.store {
  padding: 100px 0;
  background-color: $white;
  z-index: 5;
  position: relative;

  &-wrap {
    h2 {
      letter-spacing: -0.02em;
      font-size: 48px;
      font-weight: 500;
      line-height: 56px;
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 80px;
    }
    span {
      @include gradient;
    }
  }
  &-content {
    display: flex;
    gap: 40px;
  }
  &-row {
    width: calc(100% / 3 - 80px / 3);
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    justify-content: center;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-card {
      text-align: center;
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 980px) {
  .store {
    &-content {
      flex-direction: column;
      gap: 60px;
    }
    &-row {
      width: 100%;
      align-items: center;

      &-img {
        height: 600px;
        object-fit: contain;
      }

      &-card {
        max-width: 500px;
        // margin: 0 auto;
      }
    }
  }
}
@media (max-width: 500px) {
  .store {
    padding: 60px 0;
    &-wrap {
      h2 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
    }
    &-content {
      flex-direction: column;
      gap: 60px;
    }
    &-row {
      &-img {
        height: 400px;
      }
      &-card {
        max-width: 500px;
        // margin: 0 auto;
      }
    }
  }
}
.store-row-card-dot {
  width: 100%;
  height: 100%;
  background-color: #9b41e9;
  border-radius: 50%;
  &-wrap {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 6px;
    margin: 0 auto 16px;
    background-color: #e1c6f8;
  }
}
