@import "src/styles/variables";

.features {
  padding: 100px 0;
  background-color: $white;
  z-index: 5;
  position: relative;

  &-wrap {
    h2 {
      letter-spacing: -0.02em;
      font-size: 48px;
      font-weight: 500;
      line-height: 56px;
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 80px;
      @include gradient;
    }
  }
  &-content {
    @include flex;
    gap: 80px;
    // background-color: red;
  }
  &-left {
    width: 60%;
    @include scroll-none;
    height: 480px;
    overflow-y: scroll;

    div {
      width: 100%;
      height: 480px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-right {
    width: 40%;

    .ant-collapse-header-text {
      font-size: 20px;
    }
  }
}

@media (max-width: 850px) {
  .features {
    &-content {
      flex-direction: column;
      gap: 60px;
    }
    &-left {
      width: 100%;
    }
    &-right {
      width: 100%;
    }
  }
}
@media (max-width: 850px) {
  .features {
    padding: 60px 0;
    &-wrap {
      h2 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
    }

    &-content {
      flex-direction: column;
      gap: 60px;
    }
    &-right {
      .ant-collapse-header-text {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 500px) {
  .features {
    &-left {
      height: 250px;
      div {
        height: 250px;
      }
    }
  }
}
